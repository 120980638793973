<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body text-center">
        <a
          href="https://play.google.com/store/apps/details?id=com.brmja.biokeep"
          target="_blank"
        >
          <img
            src="https://cdn-static.brmja.com/storage/scrapped/6381cf046dbb7307884481.jpg"
            style="width: 200px; max-width: 100%"
            alt=""
          />
        </a>
        &nbsp;
        <a
          href="https://apps.apple.com/app/biokeep/id6444685674"
          target="_blank"
        >
          <img
            src="https://cdn-static.brmja.com/storage/scrapped/6390960ccd133752672955.png"
            style="width: 200px; max-width: 100%"
            alt=""
          />
        </a>
        <br />
        {{ e("app-exdate") }}: {{ user.app_exdate }}
        <br />
        {{ e("app-limit") }}: {{ user.app_limit }}
      </div>
    </div>
    <div class="col-12">
      <div class="card card-body">
        <h5 class="g">
          {{ e("locations") }} -
          <button
            class="btn btn-relief-success"
            v-b-modal.modal-1
            @click="
              addObject = {
                to: 'group',
                locations: [],
              };
              edit = false;
            "
          >
            <i class="far fa-plus"></i>
            {{ e("add-location") }}
          </button>
        </h5>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>
                {{ e("title") }}
              </th>
              <th>
                {{ e("selected-to") }}
              </th>
              <th>
                {{ e("locations-count") }}
              </th>
              <th>
                {{ e("options") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="location in locations" :key="location._id">
                <td>
                  {{ location.title }}
                </td>
                <td
                  v-html="
                    location.to == 'group'
                      ? e('settings-group') +
                        '<br />' +
                        groupName(location.to_id)
                      : e('user') + '<br />' + userName(location.to_id)
                  "
                ></td>
                <td>
                  {{ location.locations.length }}
                </td>
                <td>
                  <button
                    @click="
                      addObject = location;
                      edit = 1;
                    "
                    v-b-modal.modal-1
                    class="btn btn-sm btn-relief-success fa fa-edit"
                  ></button>
                  &nbsp;
                  <button
                    @click="deleteLocation(location._id)"
                    class="btn btn-sm btn-outline-danger fa fa-trash"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      :title="e('add-location')"
      ok-only
      hide-footer
      size="lg"
    >
      <div class="form-group">
        <h5 for="">{{ e("title") }}</h5>
        <input
          type="text"
          class="form-control"
          v-model="addObject.title"
          :placeholder="e('write-here')"
        />
      </div>
      <div class="form-group">
        <h5 for="">{{ e("add-location-to") }}</h5>
        <select class="form-control" v-model="addObject.to">
          <option value="user">{{ e("user") }}</option>
          <option value="group">{{ e("settings-group") }}</option>
        </select>
      </div>
      <div class="form-group" v-if="addObject.to == 'user'">
        <h5 for="">{{ e("select-user") }}</h5>
        <select class="form-control" v-model="addObject.to_id">
          <option
            :value="member._id"
            v-for="(member, index) in members"
            :key="index"
          >
            {{ member.number }} - {{ member.name }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="addObject.to == 'group'">
        <h5 for="">{{ e("select-group") }}</h5>
        <select class="form-control" v-model="addObject.to_id">
          <option
            :value="group._id"
            v-for="(group, index) in groups"
            :key="index"
          >
            {{ group.title }}
          </option>
        </select>
      </div>
      <p>
        {{ e("locations-list") }}
        <button class="btn btn-link" v-b-modal.modal-2>
          <i class="fas fa-plus"></i>
          {{ e("add") }}
        </button>
      </p>
      <div class="row">
        <div
          class="col-12 col-lg-6 g"
          v-for="(location, index) in addObject.locations"
          :key="location.key"
        >
          <l-map :zoom="zoom" :center="[location.lat, location.long]">
            <l-tile-layer :url="url" />
            <l-marker :lat-lng="[location.lat, location.long]" />
            <l-circle
              :lat-lng="[location.lat, location.long]"
              :radius="new Number(location.radius)"
              :color="'#EA5455'"
            />
          </l-map>
          <a class="btn-link text-danger" @click="deleteLMap(index)">
            <i class="fa fa-times"></i>
            {{ e("delete") }}
          </a>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-relief-primary" @click="addNow()" v-if="!edit">
          {{ e("add-now") }}
          <i class="fa fa-arrow-left"></i>
        </button>
        <button class="btn btn-relief-primary" @click="editNow()" v-if="edit">
          {{ e("edit") }}
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-2" :title="e('map')" ok-only hide-footer size="lg">
      <l-map
        :zoom="zoom"
        @click="addMarker"
        :center="[mylat, mylong]"
        v-if="viewMap__1"
      >
        <l-tile-layer :url="url" />
      </l-map>
      <button @click="viewMap__1 = true" class="btn btn-primary">
        <i class="fa fa-map"></i> {{ e("select-from-map") }}
      </button>
      &nbsp;
      <button class="btn btn-default" @click="manualLatlong()">
        <i class="fa fa-edit"></i> {{ e("insert-latlong-manual") }}
      </button>
    </b-modal>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BLink, BOverlay, BFormCheckbox, BModal, VBModal } from "bootstrap-vue";
//

/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet";
import { Icon } from "leaflet";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
//
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
    BModal,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LGeosearch,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      center: [47.31322, -1.319482],
      //
      loading: true,
      addObject: {
        to: "group",
        locations: [],
      },
      tmp: {},
      members: [],
      viewMap__1: false,
      groups: [],
      edit: false,
      mylat: null,
      mylong: null,
      locations: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    setInterval(() => {
      if (!$(".vue2leaflet-map").is(":visible")) {
        g.viewMap__1 = false;
      }
    });
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        g.getUsers();
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (r) {
        g.mylat = r.coords.latitude;
        g.mylong = r.coords.longitude;
      });
    } else {
      alert("your browser is not support maps!");
    }
  },

  methods: {
    e(d) {
      return e(d);
    },
    getUsers() {
      var g = this;
      g.loading = true;
      $.post(api + "/users/index-all", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          g.members = JSON.parse(r).response;
          g.getLocations();
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    getLocations() {
      var g = this;
      this.loading = true;
      $.post(api + "/locations/index", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          g.locations = JSON.parse(r).response;
          g.loading = false;
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    groupName(id) {
      var title = null;
      this.groups.forEach((element) => {
        if (element._id == id) {
          title = element.title;
        }
      });
      return title;
    },
    userName(id) {
      var title = null;
      this.members.forEach((element) => {
        if (element._id == id) {
          title = element.name;
        }
      });
      return title;
    },
    addLocation(latlong, radius) {
      this.addObject.locations.push({
        lat: latlong.split(",")[0],
        long: latlong.split(",")[1],
        radius: radius,
        key: Math.random(),
      });
    },
    addMarker(event) {
      var radius = prompt(e("whats-raduis"), "200");
      if (radius != null && radius != "") {
        this.addLocation(
          event.latlng.lat + "," + event.latlng.lng,
          new Number(radius)
        );
        $("#modal-2___BV_modal_header_ .close").click();
      }
    },
    deleteLMap: function (index) {
      this.addObject.locations.splice(index, 1);
    },
    addNow() {
      var g = this;
      if (this.addObject.locations.length == 0) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: e("must-add-locations"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        if (this.addObject.title == null || this.addObject.to_id == null) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: e("required-inputs"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        } else {
          g.loading = true;
          $.post(api + "/locations/add", {
            jwt: g.user.jwt,
            object: g.addObject,
          })
            .then(function (r) {
              g.locations = JSON.parse(r).response;
              g.loading = false;
              $("#modal-1___BV_modal_header_ .close").click();
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("done"),
                  icon: "TimesIcon",
                  variant: "success",
                },
              });
            })
            .catch(function (e) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error: " + e,
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    editNow() {
      var g = this;
      if (this.addObject.locations.length == 0) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: e("must-add-locations"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        if (this.addObject.title == null || this.addObject.to_id == null) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: e("required-inputs"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        } else {
          g.loading = true;
          $.post(api + "/locations/edit", {
            jwt: g.user.jwt,
            object: g.addObject,
          })
            .then(function (r) {
              g.locations = JSON.parse(r).response;
              g.loading = false;
              $("#modal-1___BV_modal_header_ .close").click();
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("done"),
                  icon: "TimesIcon",
                  variant: "success",
                },
              });
            })
            .catch(function (e) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error: " + e,
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    deleteLocation(id) {
      if (confirm(e("confirm"))) {
        var g = this;
        g.loading = true;
        $.post(api + "/locations/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          g.locations = JSON.parse(r).response;
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: e("done"),
              icon: "TimesIcon",
              variant: "success",
            },
          });
        });
      }
    },
    manualLatlong() {
      var latlong = prompt("lat,long", "");
      if (latlong != "" && latlong) {
        var g = this,
          lat = latlong.split(",")[0],
          long = latlong.split(",")[1];
        var radius = prompt(e("whats-raduis"), "200");
        if (radius != null && radius != "") {
          g.addLocation(lat + "," + long, new Number(radius));
          $("#modal-2___BV_modal_header_ .close").click();
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
#modal-2___BV_modal_body_ .vue2leaflet-map * {
  cursor: pointer;
}
</style>
 